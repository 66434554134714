import Dexie from 'dexie';
import type { Notification } from '../notification';

export class CrytpotendiesNotificationDatabase extends Dexie {
  notifications: Dexie.Table<Notification, number>;

  constructor() {
    super('CrytpotendiesNotificationDatabase');

    this.version(1).stores({
      notifications: 'id,timestamp,title,body',
    });

    this.notifications = this.table('notifications');
  }
}

const db = new CrytpotendiesNotificationDatabase();

export const database = db;
