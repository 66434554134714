import React from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '../contexts/AuthContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WithAuth = Component => {
  const Auth = props => {
    const router = useRouter();
    const { user } = useAuth();

    if (!user) {
      router.push('/login');

      return null;
    }

    return <Component {...props} />;
  };

  // Copy in getInitialProps data
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default WithAuth;
