import type { Dispatch, SetStateAction } from 'react';
import { getMessaging } from 'firebase/messaging';
import type { Messaging, MessagePayload } from 'firebase/messaging';
import type { User } from 'firebase/auth';

let messaging: Messaging | null = null;

if (process.browser) {
  messaging = getMessaging();
}

// This prevents mutliple tokens from being stored in the Firestore database
// during reactive iterations before the upstream collection can save the document.
let isStoringToken = false;

export const getMessagingToken = async () => {
  const { getToken } = await import('firebase/messaging');

  return messaging ? getToken(messaging) : null;
};

const getUserTokenDocumentId = async (user: User) => {
  const { uid } = user;
  const { getCollectionDocuments } = await import('./database');

  const [documents, token] = await Promise.all([
    getCollectionDocuments('push_tokens'),
    getMessagingToken(),
  ]);

  if (!documents || !token) {
    return null;
  }

  let id = null;

  documents.forEach(document => {
    const data = document.data();

    if (data.uid === uid && data.token === token) {
      id = document.id;
    }
  });

  return id;
};

const storeMessagingToken = async (user: User) => {
  const { database } = await import('./database');

  if (!database) {
    throw new Error('A Firebase Database instance does not exist.');
  }

  // This prevents mutliple tokens from being stored in the Firestore database
  // during reactive iterations before the upstream collection can save the document.
  if (isStoringToken) {
    return;
  }

  isStoringToken = true;

  const [{ collection, addDoc, serverTimestamp }, token, hasStoredToken] =
    await Promise.all([
      import('firebase/firestore'),
      getMessagingToken(),
      getUserTokenDocumentId(user),
    ]);

  const reference = collection(database, 'push_tokens');

  if (!hasStoredToken) {
    await addDoc(reference, {
      uid: user.uid,
      token,
      createdAt: serverTimestamp(),
      userAgent: navigator?.userAgent || '',
    });
  }

  isStoringToken = false;
};

export const deleteMessagingToken = async (user: User) => {
  const [documentId, { database }, { doc, deleteDoc }] = await Promise.all([
    getUserTokenDocumentId(user),
    import('./database'),
    import('firebase/firestore'),
  ]);

  if (database && documentId) {
    await deleteDoc(doc(database, 'push_tokens', documentId));
  }
};

export const requestFirebaseNotificationPermission = async (
  setTokenFound: Dispatch<SetStateAction<boolean>>,
  user?: User,
): Promise<void> => {
  const token = await getMessagingToken();

  if (user && token) {
    storeMessagingToken(user);
  }

  setTokenFound(Boolean(token));
};

export const onFirebaseMessage = (): Promise<MessagePayload | null> =>
  new Promise(resolve =>
    import('firebase/messaging').then(({ onMessage }) =>
      messaging
        ? onMessage(messaging, payload => resolve(payload))
        : resolve(null),
    ),
  );
