import React, { useState } from 'react';
import type { FC, ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { useLiveQuery } from 'dexie-react-hooks';
import type { NotificationPayload } from 'firebase/messaging';
import { useAuth } from '../contexts/AuthContext';
import WithAuth from '../components/WithAuth';
import { requestFirebaseNotificationPermission } from '../utilities/firebase/messaging';
import { database } from '../utilities/databases/notifications';

// Dynamic imports
const Head = dynamic(() => import('next/head'));
const Link = dynamic(() => import('next/link'));
const LogoHeader = dynamic(() => import('../components/LogoHeader'));
const Notifications = dynamic(() => import('../components/Notifications'));
const Alert = dynamic(() => import('@mui/material/Alert'));
const Container = dynamic(() => import('@mui/material/Container'));
const Button = dynamic(() => import('@mui/material/Button'));
const Box = dynamic(() => import('@mui/material/Box'));

const Dashboard: FC = (): ReactElement => {
  const { user } = useAuth() || {};
  const [isTokenFound, setTokenFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const notifications = useLiveQuery(() =>
    database.notifications.orderBy('timestamp').reverse().toArray(),
  );

  const errorHandler = async (error: Error) => {
    const { logError } = await import('../utilities/logger');

    logError(error);
  };

  const showNotification = (
    title: string,
    notification: NotificationPayload,
  ) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then(registration =>
          registration?.showNotification(title, notification),
        );
    }
  };

  const databaseCleanup = () => {
    database.notifications.orderBy('timestamp').reverse().offset(25).delete();
  };

  const initiateOnMessageHandler = async () => {
    try {
      const [{ onFirebaseMessage }, { getNormalizedNotification }] =
        await Promise.all([
          import('../utilities/firebase/messaging'),
          import('../utilities/notification'),
        ]);

      const payload = await onFirebaseMessage();

      if (!payload) {
        return;
      }

      const notification = getNormalizedNotification(payload);

      const { body } = notification;

      showNotification(notification.title, { body });

      database.notifications.add(notification);

      // Remove any notification beyond the most recent 25 from the database
      databaseCleanup();
    } catch (error) {
      errorHandler(error);
    }
  };

  if (process.browser) {
    requestFirebaseNotificationPermission(setTokenFound, user)
      .then(() => {
        // This can not be returned or the catch all `then` promise handler will not fire
        initiateOnMessageHandler();
      })
      .catch(error => {
        errorHandler(error);
      })
      .then(() => setIsLoading(false));
  }

  return (
    <>
      <Head>
        <title>Dashboard - CryptoTendies Notification PWA</title>
      </Head>
      <Container maxWidth="md">
        <LogoHeader title="Dashboard" />
        {!isLoading && (
          <>
            {!isTokenFound && (
              <Alert severity="warning">
                The application needs permission to accept new notifications
              </Alert>
            )}
            {isTokenFound && <Notifications notifications={notifications} />}
            <Link href="/profile">
              <Box sx={{ my: 3 }}>
                <Button variant="contained" size="small" disableElevation>
                  Go To Account Page
                </Button>
              </Box>
            </Link>
          </>
        )}
      </Container>
    </>
  );
};

export default WithAuth(Dashboard);
